<ng-container *ngIf="auth.loggedIn() && channels?.length!==0">
    <p class="subscriptions-text">Subscriptions</p>
    <div class="left-list" cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="channel-block subscription" *ngFor="let channel of channels;let i = index;trackBy: trackByElement"
             cdkDrag
             (cdkDragDropped)="dragDropped($event, channel)" (click)="openChannelPage(channel)">
            <ng-container *ngIf="i < 5">
                <img class="list-img" [src]="channel.avatar | getImgPath:'images'">
                <div class="">
                    <p class="channel-name">{{channel.name}}</p>
                </div>
            </ng-container>
        </div>
    </div>
    <p (click)="viewAllSubscriptions()" *ngIf="routerUrl !== '/users/profile/channel-subscriptions/' + authUser?.channel.id"
       class="see-more">
        See more</p>
</ng-container>
