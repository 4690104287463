<!--<ng-container *ngIf="videos?.length > 0">-->
<p class="trending-text" *ngIf="videos?.length > 0">{{title}}</p>
<!--    <div class="ph-item item">-->
<!--        <div class="ph-col-12">-->
<!--            <div class="ph-picture"></div>-->

<!--            <div class="d-flex">-->
<!--                <div class="ph-avatar mr-3"></div>-->

<!--                <div class="ph-col-10">-->
<!--                    <div class="ph-row align-items-start">-->
<!--                        <div class="ph-col-2"></div>-->
<!--                        <div class="ph-col-8 empty"></div>-->
<!--                        <div class="ph-col-2"></div>-->
<!--                    </div>-->
<!--                    <div class="ph-row align-items-start">-->
<!--                        <div class="ph-col-4"></div>-->
<!--                    </div>-->
<!--                    <div class="ph-row align-items-start">-->
<!--                        <div class="ph-col-6"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->


<!--        </div>-->
<!--    </div>-->
<div class="list-container d-flex flex-wrap justify-content-center justify-content-lg-start">
    <ng-container *ngFor="let video of videos; trackBy: trackByElement">
        <div class="item mx-2 ml-lg-0  mr-lg-4">

            <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{details: detailsSource || video.user}">
            </ng-container>

            <ng-template let-d="details" #itemTemplate>
                <div class="thumbnail" (click)="openVideoPage(video, d?.username)">
                    <img class="thumbnail-image" [src]="video.thumbnail|getImgPath:'images'">
                    <span class="live-now" *ngIf="video.status==='live'">Live now</span>
                    <span class="duration" *ngIf="video.status!=='live'">{{video.duration}}</span>
                </div>
                <div class="video-info">
                    <div class="left-side">
                        <img class="avatar" [src]="video?.user?.avatar|getImgPath: 'images'">
                        <div>
                            <p class="video-name">{{video.name}}</p>
                            <p class="channel-sub-name" [routerLink]="'/channels/'+video?.user?.username"
                            >{{video?.user?.username}}</p>
<!--                            <p class="channel-sub-name"-->
<!--                            >Test</p>-->
                            <div class="shortened-details">
                                    <span class="watchers-count">
                                        {{video.status === 'live' ? (video.participants| kMFormatter) + ' watching' : video.views + ' view' + (video.views === 1 ? '' : 's')}}
                                    </span>
                                <span class="dot">&middot;</span>
                                <span class="upload-time">{{video.created_at| dateFromNow}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="right-side">
                        <ul>
                            <li *ngFor="let tag of video?.tags" (click)="getVideosByTag(tag.name)">
                                <span>{{tag.name}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="remove-video" *ngIf="removable">
                    <mat-icon (click)="removeVideo(video)">delete</mat-icon>
                </div>
                <div class="update-privacy" *ngIf="isChannelPage()" (click)="updatePrivacy(video, video.privacy?.name)"
                     [ngClass]="video.privacy?.name !== 'Public'  ? 'red':'green'">
                    <mat-icon>visibility_{{video.privacy?.name !== 'Public' ? 'off' : 'on'}}</mat-icon>
                </div>
            </ng-template>


        </div>
    </ng-container>
</div>
<!--</ng-container>-->
