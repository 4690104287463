<div id="post-form-container">
    <form>
        <div class="user-avatar" *ngIf="(userStore.authUser$|async) as authUser">
            <img class="avatar" [src]="authUser.avatar|getImgPath: 'images'">
        </div>

        <div class="form-controls">
            <textarea placeholder="Create a public post..." routerLink="/posts/create"
                      [queryParams]="{group_id: selectedGroup?.id}"></textarea>
            <div class="action-btn-wrap">
                <button (click)="uploadDialog()" >
                    <img src="assets/img/photo-green-small.svg" alt="">
                    <span>Add photo/video</span>
                </button>
                <p>{{videoName}}</p>
                <mat-spinner *ngIf="videoUploadSpinner" [diameter]="24"></mat-spinner>
                <mat-icon *ngIf="finishVideoUpload" style="background-color: #0aa06e; color: #ffffff">done</mat-icon>
            </div>
        </div>
    </form>
</div>
