<div class="item-video-skeleton">
    <div class="item-video-img-skeleton">

    </div>
    <div class="item-video-desc-skeleton">
        <div class="item-video-channel-skeleton">
            <div class="item-video-channel-img-skeleton">

            </div>

            <div class="item-video-name_tag-skeleton">
                <div class="item-video-name-skeleton">
                    <p class="video-name-skeleton"></p>
                    <p class="channel-name-skeleton"></p>
                    <p class="view_date-skeleton">
                        <span class="view-skeleton"></span>
                        <span class="date-skeleton"></span>
                    </p>
                </div>
                <div class="video_tag-skeleton">
                    <span class="tag-skeleton"></span>
                    <span class="tag-skeleton"></span>
                    <span class="tag-skeleton"></span>
                    <span class="tag-skeleton"></span>
                </div>
            </div>
        </div>
    </div>
</div>
