import { BaseState } from '@core/services/state/base.state';
import { CommentInterface } from '@core/interfaces/comment.interface';
import { SetCommentInterface } from '@core/interfaces/comment.interface';
import { ReplayCommentDataInterface } from '@core/interfaces/replay-comment.interface';


export const videoCommentInitialState: any = {
    comments: undefined,
    loading: false
};

export class VideoCommentState extends BaseState<any> {
    constructor(initialInputData: any = videoCommentInitialState) {
        super(initialInputData);
    }


    setVideoComment(comments: CommentInterface[]): void {
        // console.log(comments);
        this.setState({
            ...this.state,
            comments: [
                ...comments
            ]
        });
    }
// SetCommentInterface
    createCommentMain(sendComment: any): void {
        if (sendComment.is_reply === false) {
            // console.log(sendComment, 'sendComment');
            this.setState({
                ...this.state,
                comments: [
                    Object.assign(sendComment),
                    ...this.state.comments
                ]
            });
        }
    }

    setCommentMainReplay(id: number, replayComment: ReplayCommentDataInterface []) {
        const data = this.state.comments.map((res) => {
            if (res.id === id) {
                console.log(123);
                res.replayComments = replayComment;
            }
            return res;
        });

        console.log(data);
    }

    setLoading(loading: boolean): void {
        this.setState({
            ...this.state,
            loading
        });
    }

}

