import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommentListComponent } from './comment-list.component';
import { CommentListItemComponent } from './comment-list-item';
import { MaterialModule } from '@core/modules/material.module';
import { ReportCommentDialog } from '@shared/components/commnet-list/report-comment';
import { SharedModule } from '@shared/shared.module';
import { CommentReplayComponent } from '@shared/components/commnet-list/comment-replay';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteCommentDialog } from '@shared/components/commnet-list/delete-comment';


@NgModule({
    declarations: [
        CommentListComponent,
        CommentListItemComponent,
        CommentReplayComponent,
        ReportCommentDialog,
        DeleteCommentDialog,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        ReactiveFormsModule
    ],

    exports: [
        CommentListComponent
    ],
    entryComponents: [ReportCommentDialog, DeleteCommentDialog],
})

export class CommentListModule {
}
