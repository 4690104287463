import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-new-home',
    templateUrl: 'new-home.component.html',
    styleUrls: ['new-home.component.scss']
})

export class NewHomeComponent implements OnInit, OnDestroy {


    constructor(

    ) {
    }

    ngOnInit() {

    }

    ngOnDestroy() {
    }
}
