import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NewPlaylistService } from '@core/services/new-playlist.service';
import { Observable } from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import { VideoCommentInitialState } from '@core/interfaces/comment.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserInfoService } from '@core/services/user-info.service';
import { CurrentUserData } from '@core/interfaces';
import { SocketIoService } from '@core/services/socket-io.service';

@Component({
    selector: 'app-comment-list',
    templateUrl: 'comment-list.component.html',
    styleUrls: ['comment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommentListComponent implements OnInit, OnDestroy, OnChanges {
    @Input() videoId;
    public videoCommentDataSource$: Observable<VideoCommentInitialState> | undefined;
    public commentFormGroup: FormGroup | undefined;
    public showFormBtn = false;
    public userData: CurrentUserData;

    constructor(
        private _fb: FormBuilder,
        private _userInfoService: UserInfoService,
        private _newPlaylistService: NewPlaylistService,
        private _socketIoService: SocketIoService,
        private toastr: ToastrService,
    ) {
        this._currentUserData();
        this.videoCommentDataSource$ = this._newPlaylistService.videoCommentState$.state$;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('onchange11111111', changes);
        this.getCommentList(changes.videoId.currentValue);
        this._socketIoService.connectionComment({ video_id: changes.videoId.currentValue, post_id: null });
    }


    ngOnInit() {
        this._FormBuilder();
        this._socketIoService.createComment_success().subscribe((data) => {
            this._newPlaylistService.videoCommentState$.setLoading(true);
            let dataParse: string | undefined;
            if (typeof data === 'string') {
                dataParse = JSON.parse(data);
                this._newPlaylistService.videoCommentState$.createCommentMain(dataParse);
                this._newPlaylistService.videoCommentState$.setLoading(false);
                console.log(dataParse, 'createComment_success');
            }
        });
        this.getNotify();
    }

    public getNotify() {
        this._socketIoService.listenToNotifications().subscribe((data) => {
            // console.log(data, '@@@@@@@@@@@@@');
        });
    }

    private _currentUserData() {
        this._userInfoService._userInfo
            .subscribe((data: CurrentUserData) => {
                this.userData = data;
            });
    }

    private _FormBuilder() {
        this.commentFormGroup = this._fb.group({
            comment: [null, Validators.required]
        });
    }


    public showBtn() {
        this.showFormBtn = true;
    }

    public getCommentList(id) {
        const OBJ = {
            type: 'video',
            video_id: id
        };
        this._newPlaylistService.getVideoComment(OBJ);
    }

    public createComment() {
        if (this.commentFormGroup.invalid) {
            return;
        }
        if (this.userData) {
            console.log(this.userData);
            const OBJ_SET_COMMENT = {
                comment: this.commentFormGroup.get('comment').value,
                from_id: this.userData.id,
                post_id: null,
                is_reply: false,
                video_id: this.videoId,
                parent_comment: null,
                files: []
            };
            // console.log(OBJ_SET_COMMENT,"OBJ_SET_COMMENT");
            this._socketIoService.addComment(OBJ_SET_COMMENT);
        } else {
            this.toastr.error('You need to login or create an account with www.metl.tv to leave a comment');
        }


        this._FormBuilder();
        this.showFormBtn = false;
    }
    public cancelComment() {
        this._FormBuilder();
        this.showFormBtn = false;
    }


    ngOnDestroy() {
    }
}
