import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@core/constants/global';
import { NewPlaylistInterface } from '@core/interfaces/new-playlist.interface';
import { NewPlaylistState } from '@core/services/state/new-playlist.state';
import { NewPlayListVideosState } from '@core/services/state/new-playList-videos.state';
import { CommentInterface, CommentListResponseInterface } from '@core/interfaces/comment.interface';
import { VideoCommentState } from '@core/services/state/video-comment.state';
import { ReplayCommentState } from '@core/services/state/replay-comment.state';
import { ReplayCommentResponsInterface } from '@core/interfaces/replay-comment.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NewPlaylistService {
    public newPlaylistState$: NewPlaylistState = new NewPlaylistState();
    public newPlayListVideosState$: NewPlayListVideosState = new NewPlayListVideosState();
    public videoCommentState$: VideoCommentState = new VideoCommentState();
    public replayCommentState$: ReplayCommentState = new ReplayCommentState();
    private _unsubscribe$ = new Subject<void>();

    constructor(private _httpClient: HttpClient) {
    }

    public createPlayList(formData: any) {
        console.log(11111);
        this.newPlaylistState$.setLoading(true);
        this._httpClient.post(`${API_URL}playlists/add`, formData)
            .subscribe((data: NewPlaylistInterface) => {
                this.newPlaylistState$.setLoading(false);
                console.log(22222);
                this.newPlaylistState$.createPlayList(data);
            });
    }

    public getAllPlayList() {
        this.newPlaylistState$.setLoading(true);
        this._httpClient.get<NewPlaylistInterface>(`${API_URL}playlists/get`)
            .subscribe((data: NewPlaylistInterface) => {
                this.newPlaylistState$.setLoading(false);
                this.newPlaylistState$.setPlayList(data);
            });
    }

    public remove(id: number) {
        this.newPlaylistState$.setLoading(true);
        this._httpClient.delete<any>(`${API_URL}playlists/remove?id=${id}`)
            .subscribe((data: any) => {
                this.newPlaylistState$.setLoading(false);
                this.newPlaylistState$.deletePlayList(id);
            });
    }


    public getVideosPlayListById(params: any) {
        this.newPlayListVideosState$.setLoading(true);
        this._httpClient.get<NewPlaylistInterface>(`${API_URL}playlists/get-by-id`, { params })
            .subscribe((data: NewPlaylistInterface) => {
                console.log(data);
                this.newPlayListVideosState$.setLoading(false);
                this.newPlayListVideosState$.setVideos(data);
            });
    }


    public getVideoComment(params) {
        // console.log(params, 'getVideoComment');
        this.videoCommentState$.setLoading(true);
        this._httpClient.get<CommentListResponseInterface>(`${API_URL}comments/getList/${params.type}/${params.video_id}`)
            .pipe(
                takeUntil(this._unsubscribe$)
            )
            .subscribe((data: CommentListResponseInterface) => {
                this.videoCommentState$.setLoading(false);
                this.videoCommentState$.setVideoComment(data.data.rows);
            });
    }

    public getVideoCommentReplay(comment_id) {
        this.videoCommentState$.setLoading(true);
        this._httpClient.get<ReplayCommentResponsInterface>(`${API_URL}comments/replyList/${comment_id}`)
            .subscribe(({ data }: ReplayCommentResponsInterface) => {
                this.videoCommentState$.setCommentMainReplay(comment_id, data);
                this.videoCommentState$.setLoading(false);
            });
    }

    public createVideoComment(params) {
        this.newPlaylistState$.setLoading(true);
        this._httpClient.post<any>(`${API_URL}videos/add-comment`, params)
            .subscribe((data: CommentInterface[]) => {
                this.videoCommentState$.setLoading(false);
                this.videoCommentState$.setVideoComment(data);
            });
    }

    public updateComment(params) {
        this.newPlaylistState$.setLoading(true);
        this._httpClient.put<any>(`${API_URL}videos/update-comment`, params)
            .subscribe((data: CommentInterface[]) => {
                this.videoCommentState$.setLoading(false);
                this.videoCommentState$.setVideoComment(data);
            });
    }


    public removeVideoComment(params) {
        this._httpClient.delete<any>(`${API_URL}videos/remove-comment`, { params })
            .subscribe((data: CommentInterface[]) => {
                console.log(data);
                this.videoCommentState$.setLoading(false);
                this.videoCommentState$.setVideoComment(data);
            });
    }


    public setVideoPlayList(formData) {

        return this._httpClient.post(`${API_URL}playlists/add-videos`, formData);
    }


}
