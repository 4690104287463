<div class="security text-list">
    <h1>METL.TV Security</h1>
    <div class="text">
        <p class="bold green">METL.TV Security</p>
        <p>METL.TV Security is responsible for creating the systems that protect the many streamers and stream viewers that use our platform.</p>
        <p>While METL.TV Security’s main goal is protecting those on our platform, we primarily act in a technical capacity, designing and securing METL.TV’s systems. If someone else has logged into your account without your permission or someone is breaking our rules, or if you think you have technical details of a security flaw please email us immediately at info@metl.tv.</p>
        <p class="bold green">Protecting Your Account</p>
        <p class="bold red">Keep your login safe!</p>
        <p>Anyone with your username and password can get into your METL.TV account. Never tell anyone your password, not even someone at METL.TV. We will never ask for your password.</p>
        <p>We recommend you use a password on METL.TV that you don’t use anywhere else. It might be hard to remember lots of passwords so it might be worth finding a good password manager to keep all your passwords in a safe place where you don’t have to remember all of them.</p>
        <p class="bold green">Two Factor Authentication (2FA)</p>
        <p>Two factor authentication is one of the best things you can do to protect your METL.TV account from the bad guys out there. You can enable it from your security settings page. Now, even if someone steals your password, they will have to also steal a code from your phone to log in. This will make it much harder for bad people to get into your account.</p>
        <p class="bold green">Verify your Email</p>
        <p>If you forget your password you will need a verified email to get back into your account. Make sure to verify your account via the steps we sent you.</p>
        <p class="bold green">Staying Safe</p>
        <p>It doesn’t hurt to be a little cautious when protecting yourself online. Avoid clicking links or downloading files from suspicious strangers and keep your devices up to date with the latest updates.</p>
        <p class="bold green">Responsible Disclosure</p>
        <p>If you believe you’ve discovered a vulnerability in METL.TV that puts our users at risk and can give technical details, please contact us immediately.</p>
        <p>If you believe that such security issue exists but cannot give technical details, please contact info@metl.tv, who will handle the issue appropriately.</p>
    </div>
</div>