<div class="container">
    <video class="video-js" #target controls autoplay
           width="640"
           height="480"
           data-setup="{}"></video>
</div>
<!--<div class="container">-->

<!--    <video width="640" height="480" controls muted playsinline id="player" preload="none">-->
<!--        &lt;!&ndash;    <source src="https://vjs.zencdn.net/v/oceans.mp4?sd" type='video/mp4' label='SD' res='480' />&ndash;&gt;-->
<!--        &lt;!&ndash;    <source src="https://vjs.zencdn.net/v/oceans.mp4?hd" type='video/mp4' label='HD' res='1080'/>&ndash;&gt;-->
<!--        &lt;!&ndash;    <source src="https://vjs.zencdn.net/v/oceans.mp4?phone" type='video/mp4' label='phone' res='144'/>&ndash;&gt;-->
<!--        &lt;!&ndash;    <source src="https://vjs.zencdn.net/v/oceans.mp4?4k" type='video/mp4' label='4k' res='2160'/>&ndash;&gt;-->
<!--    </video>-->
<!--</div>-->


<!--<div class="container">-->
<!--    <video controls crossorigin playsinline>-->
<!--&lt;!&ndash;        <source src="http://localhost:3001/uploads/videos/1613738222158.mkv"  type="video/webm" size="576">&ndash;&gt;-->
<!--&lt;!&ndash;        <source  src="http://localhost:3001/uploads/videos/1613738222158.mkv"type="video/webm" size="720">&ndash;&gt;-->
<!--&lt;!&ndash;        <source  src="http://localhost:3001/uploads/videos/1613738222158.mkv" type="video/webm" size="1080">&ndash;&gt;-->

<!--        &lt;!&ndash; Caption files &ndash;&gt;-->
<!--&lt;!&ndash;        <track kind="captions" label="English" srclang="en" src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.en.vtt"&ndash;&gt;-->
<!--&lt;!&ndash;               default>&ndash;&gt;-->
<!--&lt;!&ndash;        <track kind="captions" label="Français" srclang="fr" src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.fr.vtt">&ndash;&gt;-->
<!--        &lt;!&ndash; Fallback for browsers that don't support the <video> element &ndash;&gt;-->
<!--        <a href="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4" download>Download</a>-->
<!--    </video>-->
<!--</div>-->
