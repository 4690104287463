<div class="video-container">
    <video id="video_{{idx}}" class="video-js" playsinline></video>
    <div id="live-session-finished" *ngIf="recordingState === 'finished'">
        <div class="messages-container">
            <h2>Live video ended</h2>
<!--            <div>{{authUser | json}}</div>-->
            <p class="back-to-channel-videos" [routerLink]="'/channels/channel-videos/'+authUser.channel.id">Go to my
                channel</p>
        </div>
    </div>
</div>


<button *ngIf="subject.publish.value" (click)="muted()" style="position: absolute; bottom: 0; left: 50%; background: transparent">
    <span class="material-symbols-outlined" style="color: red">
        mic_off
    </span>
</button>
<button *ngIf="!subject.publish.value" (click)="unMuted()" style="position: absolute; bottom: 0; left: 50%; background: transparent">
    <span class="material-symbols-outlined" style="color: red">
        mic
    </span>
</button>


<!--<video id="liveVideo" style="display: none" [src]="videoSRC" preload="auto"></video>-->
