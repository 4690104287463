<div class="page_container">
    <div class="btn_container">
        <button class="green_btn" (click)="changeVideos('new')">New</button>
        <button class="green_btn" (click)="changeVideos('trending')">Trending</button>
        <button class="green_btn" (click)="changeVideos('clipz')">Clipz</button>
    </div>
    <ng-container *ngIf="( newVideoDataSource$ | async) as state">
        <div *ngIf="page_status === 'new'" class="videos-wrapper">
            <div class="video-wrapper-title">
                <h1>New Videos</h1>
            </div>
            <div class="video-list-wrapper">
                <div class="video-list-item-content" *ngFor="let item of state.videos">
                    <app-new-video-list-item
                        class="list_item"
                        [data]="item"
                        [type]="'new'"
                        [id]="item.id"
                        [loading]="state.loading"
                    ></app-new-video-list-item>
                </div>
            </div>
<!--            <div>-->
<!--                <mat-paginator [length]="state.count"-->
<!--                               [pageSize]="_limit"-->
<!--                               (page)="onPageNewChanged($event)"-->
<!--                               aria-label="Select page">-->
<!--                </mat-paginator>-->
<!--            </div>-->
        </div>
    </ng-container>

    <ng-container *ngIf="( tradingVideoDataSource$ | async) as state">
        <div *ngIf="page_status === 'trending'" class="videos-wrapper">
            <div class="video-wrapper-title">
                <h1>Trending streams</h1>
            </div>
            <div class="video-list-wrapper">
                <div class="video-list-item-content" *ngFor="let item of state.videos">
                    <app-new-video-list-item
                        class="list_item"
                        [data]="item"
                        [type]="'trending'"
                        [loading]="state.loading"
                    ></app-new-video-list-item>
                </div>
            </div>
<!--            <div>-->
<!--                <mat-paginator [length]="state.count"-->
<!--                               [pageSize]="_limit"-->
<!--                               (page)="onPageChanged($event)"-->
<!--                               aria-label="Select page">-->
<!--                </mat-paginator>-->
<!--            </div>-->

        </div>
    </ng-container>

    <ng-container *ngIf="( clipsDataSource$ | async) as state">
        <div *ngIf="page_status === 'clipz'" class="videos-wrapper">
            <div class="video-wrapper-title">
                <h1>Trending CLIPZ</h1>
            </div>
            <div class="video-list-wrapper">
                <div class="video-list-item-content" *ngFor="let item of state.clip">
                    <app-new-video-list-item
                        class="list_item"
                        [data]="item"
                        [type]="'clipz'"
                        [loading]="state.loading">
                    </app-new-video-list-item>
                </div>
            </div>
<!--            <div>-->
<!--                <mat-paginator [length]="state.count"-->
<!--                               [pageSize]="_limit"-->
<!--                               (page)="onPageClipChanged($event)"-->
<!--                               aria-label="Select page">-->
<!--                </mat-paginator>-->
<!--            </div>-->
        </div>

    </ng-container>
</div>

