<form class="report-dialog">
    <div class="close">
        <span class="material-symbols-outlined" (click)="closeDialog()">
            cancel
        </span>
    </div>
    <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Remove Comment</mat-radio-button>
        <mat-radio-button value="2">Add Comment</mat-radio-button>
        <mat-radio-button value="2">Kick out of a chat</mat-radio-button>
        <mat-radio-button value="2">Mute user</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="outline">
        <mat-label>Comment</mat-label>
        <textarea matInput></textarea>
    </mat-form-field>

    <div class="btn-submit">
        <button (click)="saveReport()">Save</button>
    </div>
</form>
