

<ng-container *ngIf="(videoCommentDataSource$ | async) as state">
    <div class="comment-list-content" *ngIf="state.comments">
        <form class="new-comment-form" [formGroup]="commentFormGroup">
            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>New comment</mat-label>
                    <textarea matInput
                              formControlName="comment"
                              (focus)="showBtn()"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>

            <div class="btn-comment-submit" *ngIf="showFormBtn">
                <button class="btn-cancel" (click)="cancelComment()" >Cancel</button>
                <button class="btn-done" (click)="createComment()">Comment</button>
            </div>
        </form>
        <div class="comment-item-content" *ngFor="let comment of state.comments">
            <app-comment-list-item
                [comment]="comment"
            ></app-comment-list-item>
        </div>
    </div>


</ng-container>
