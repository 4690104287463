import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewPlaylistService } from '@core/services/new-playlist.service';

@Component({
    selector: 'app-delete-comment',
    templateUrl: 'delete-comment.dialog.html',
    styleUrls: ['delete-comment.dialog.scss']
})

export class DeleteCommentDialog implements OnInit, OnDestroy {

    constructor(
        private dialogRef: MatDialogRef<DeleteCommentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _newPlayListService: NewPlaylistService
    ) {
    }

    ngOnInit() {
    }

    public closeDialogConfirm() {
        this.dialogRef.close();
    }


    public confirm() {
        const OBJ_REMUVE_COMMENT = {
            user_id: this.data.user_id,
            id: this.data.commnet_id,
            video_id: this.data.video_id
        };
        this._newPlayListService.removeVideoComment(OBJ_REMUVE_COMMENT);
        this.closeDialogConfirm();
    }

    ngOnDestroy() {
    }
}
