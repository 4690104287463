import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportCommentDialog } from '@shared/components/commnet-list';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserInfoService } from '@core/services/user-info.service';
import { NewPlaylistService } from '@core/services/new-playlist.service';
import { CurrentUserData } from '@core/interfaces';
import { DeleteCommentDialog } from '@shared/components/commnet-list/delete-comment';
import { SocketIoService } from '@core/services/socket-io.service';

@Component({
    selector: 'app-comment-replay',
    templateUrl: 'comment-replay.component.html',
    styleUrls: ['comment-replay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommentReplayComponent implements OnInit, OnDestroy {
    @Input() com: any;
    public commnetFormShow = false;
    public commentUpdateFormGroup: FormGroup | undefined;
    public showFormBtn = false;
    public commentFormGroup: FormGroup | undefined;
    public userData: CurrentUserData;
    public replayCommentFormShow: true | false = false;

    constructor(
        private _fb: FormBuilder,
        private _userInfoService: UserInfoService,
        private _newPlaylistService: NewPlaylistService,
        private _socketIoService: SocketIoService,
        private dialog: MatDialog
    ) {

        this._currentUserData();
    }

    ngOnInit() {
        this._FormBuilder();
    }


    private _FormBuilder() {
        this.commentFormGroup = this._fb.group({
            comment: [null, Validators.required]
        });

        this.commentUpdateFormGroup = this._fb.group({
            commentUpdate: [null, Validators.required]
        });
    }

    private _currentUserData() {
        this._userInfoService._userInfo
            .subscribe((data: CurrentUserData) => {
                this.userData = data;
            });
    }

    public showBtn() {
        this.showFormBtn = true;
    }

    public showUpdateCommnetForm(com) {

        this.commentUpdateFormGroup.patchValue({
            commentUpdate: com.comment
        });
        this.commnetFormShow = true;
    }

    public closeUpdateCommnetForm() {
        this.commnetFormShow = false;
    }

    public openConfirmDialog(commnet_id: number, video_id: number, user_id) {
        this.dialog.open(DeleteCommentDialog, {
            height: 'auto',
            width: 'auto',
            data: {
                commnet_id,
                video_id,
                user_id
            }
        });
    }


    public createReplayComment(commentInfo) {
        console.log(commentInfo, 'steeeee');
        if (this.commentFormGroup.invalid) {
            return;
        }

        const OBJ_SET_COMMENT = {
            comment: this.commentFormGroup.get('comment').value,
            from_id: this.userData.id,
            post_id: null,
            is_reply: true,
            video_id: commentInfo.video_id,
            parent_comment: commentInfo.id,
            files: []
        };
        console.log('sasasasassas', OBJ_SET_COMMENT);
        this._socketIoService.addComment(OBJ_SET_COMMENT);
        this._FormBuilder();
        this.replayCommentFormShowFunc();
    }
    public cancelReplayComment() {
        this.replayCommentFormShow = !this.replayCommentFormShow;
    }

    public updateCommnet(commentInfo) {
        if (this.commentUpdateFormGroup.invalid) {
            return;
        }

        const OBJ_SET_COMMENT = {
            comment: this.commentUpdateFormGroup.get('commentUpdate').value,
            from_id: commentInfo.from_id,
            id: commentInfo.id,
            is_reply: 1,
            to_comment_id: commentInfo.to_comment_id,
            to_reply_id: commentInfo.to_reply_id,
            to_user_id: commentInfo.to_user_id,
            video_id: commentInfo.video_id
        };

        this._newPlaylistService.updateComment(OBJ_SET_COMMENT);
    }

    public openReportDialog() {
        const dialogRef = this.dialog.open(ReportCommentDialog, {
            height: '420px',
            width: '400px',
            panelClass: 'custom-dialog-container'
        });
    }

    public replayCommentFormShowFunc() {
        this.replayCommentFormShow = !this.replayCommentFormShow;
    }

    public likeComment(commentInfo) {
        console.log(commentInfo, 'like comment');
        const OBJ_SET_LIKE = {
            // from_id: this.userData.id,
            // post_id: null,
            // is_reply: false,
            // video_id: this.videoId,
            // parent_comment: null,
            // files: []
            comment_id: commentInfo.id
        };
        this._socketIoService.likeComment(OBJ_SET_LIKE);
    }

    public dislikeComment(commentInfo) {
        console.log(commentInfo, 'dislike comment');
        const OBJ_SET_DISLIKE = {
            // from_id: this.userData.id,
            // post_id: null,
            // is_reply: false,
            // video_id: this.videoId,
            // parent_comment: null,
            // files: []
            comment_id: commentInfo.id
        };
        this._socketIoService.likeComment(OBJ_SET_DISLIKE);
    }


    // public createReplayComment() {
    //     if (this.commentFormGroup.invalid) {
    //         return;
    //     }
    //
    //     const OBJ_SET_COMMENT = {
    //         comment: this.commentFormGroup.get('comment').value,
    //         from_id: this.userData.id,
    //         id: '',
    //         is_reply: 0,
    //         to_comment_id: 0,
    //         to_reply_id: 0,
    //         to_user_id: 0,
    //         video_id: 'this.videoId'
    //     };
    //
    //     console.log(OBJ_SET_COMMENT);
    //     this._newPlaylistService.createVideoComment(OBJ_SET_COMMENT);
    // }

    ngOnDestroy() {
    }
}
