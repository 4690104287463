import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-report-comment',
    templateUrl: 'report-comment.dialog.html',
    styleUrls: ['report-comment.dialog.scss']
})

export class ReportCommentDialog implements OnInit, OnDestroy {

    constructor(
        public dialogRef: MatDialogRef<ReportCommentDialog>
    ) {
    }

    ngOnInit() {
    }

    public closeDialog() {
        this.dialogRef.close();
    }
    public saveReport() {
        console.log('save report');
    }

    ngOnDestroy() {
    }
}
