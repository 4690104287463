import { BaseState } from '@core/services/state/base.state';
import { ReplayCommentDataInterface, ReplayCommentInitialState } from '@core/interfaces/replay-comment.interface';

export const videoCommentInitialState: ReplayCommentInitialState = {
    replayComments: [],
    loading: false
};

export class ReplayCommentState extends BaseState<ReplayCommentInitialState> {
    constructor(initialInputData: any = videoCommentInitialState) {
        super(initialInputData);
    }


    setVideoCommentReplay(comments: ReplayCommentDataInterface[]): void {
        console.log(comments);
        this.setState({
            ...this.state,
            replayComments: [
                ...comments
            ]
        });
    }

    createCommentReplays(replayComment: any): void {
        this.setState({
            ...this.state,
            replayComments: [
                Object.assign(replayComment),
                ...this.state.replayComments
            ]
        });
    }

    setLoading(loading: boolean): void {
        this.setState({
            ...this.state,
            loading
        });
    }

}

