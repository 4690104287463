<div class="comment-list-item">
    <div class="comment-list-item-main">
        <div class="user-info">
            <div class="user-img">
                <div class="user-avatar">
                    <img [src]="com.user.avatar|getImgPath:'images'" alt="">
                </div>
                <div class="user-subscription">
                    <img src="assets/img/bronzeFrame.png" alt="">
                </div>
            </div>

            <div class="user-data">
                <span class="user-nickname">{{com.user.first_name}}</span>
                <span class="user-role">Streamer</span>
            </div>
        </div>
        <div class="user-comment">
            <div class="user-comment-content">
                <p class="user-comment-text"
                   *ngIf="!commnetFormShow"
                >
                    {{com.comment}}
                </p>
                <form class="new-comment-form"
                      *ngIf="commnetFormShow"
                      [formGroup]="commentUpdateFormGroup">
                    <div class="form-group">
                        <mat-form-field appearance="fill">
                            <mat-label>Edit Comment</mat-label>
                            <textarea matInput
                                      formControlName="commentUpdate"
                                      (focus)="showBtn()"
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="btn-comment-submit" *ngIf="showFormBtn">
                        <button class="btn-cancel"
                                (click)="closeUpdateCommnetForm()">
                            Cancel
                        </button>
                        <button class="btn-done"
                                (click)="updateCommnet(com)">
                            Comment
                        </button>
                    </div>
                </form>
                <button mat-button [matMenuTriggerFor]="menu">
                <span class="material-symbols-outlined">
                more_vert
            </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                            *ngIf="com.user.id === userData.id"
                            (click)="showUpdateCommnetForm(com)">
                        Edit
                        <span class="material-symbols-outlined">
                         flag
                    </span>
                    </button>
                    <button mat-menu-item
                            *ngIf="com.user.id === userData.id"
                            (click)="openConfirmDialog(com.id,com.video_id,com.user.id)">
                        Delete
                        <span class="material-symbols-outlined">
                        flag
                    </span>
                    </button>
                    <button mat-menu-item (click)="openReportDialog()">
                        Report
                        <span class="material-symbols-outlined">
                    flag
                </span>
                    </button>
                </mat-menu>
            </div>
            <div class="comment-action">
                <p (click)="likeComment(com)">
                    <span class="material-symbols-outlined like_dislike">
                        thumb_up
                    </span>
                    <span>
                        {{com.likes_count}}
                    </span>
                </p>
                <p (click)="dislikeComment(com)">
                    <span class="material-symbols-outlined like_dislike">
                            thumb_down_off
                    </span>
                    <span>
                        {{com.dislikes_count}}
                    </span>
                </p>
                <p class="reply_comment"
                   (click)="replayCommentFormShowFunc()"
                >Reply</p>
            </div>
        </div>
    </div>
    <form class="new-comment-form"
          [formGroup]="commentFormGroup"
          *ngIf="replayCommentFormShow">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>Replay Comment</mat-label>
                <textarea matInput
                          formControlName="comment"
                          (focus)="showBtn()"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
        </div>

        <div class="btn-comment-submit" *ngIf="showFormBtn">
            <button class="btn-cancel" (click)="cancelReplayComment()">Cancel</button>
            <button class="btn-done" (click)="createReplayComment(com)">Comment</button>
        </div>
    </form>
</div>
