<div class="comment-list-item " [class.replay-border]="replayComment">
    <div class="comment-list-item-main" *ngIf="userData">
        <div class="user-info" (click)="toMessenger(comment)">
            <div class="user-img">
                <div class="user-avatar">
                    <img [src]="comment.user.avatar|getImgPath:'images'" alt="">
                </div>
                <div class="user-subscription">
                    <img src="assets/img/bronzeFrame.png" alt="">
                </div>
            </div>
            <div class="user-data">
                <span class="user-nickname">{{comment.user.first_name}}</span>
                <span class="user-role">Streamer</span>
            </div>
        </div>
        <div class="user-comment">
            <div class="user-comment-content">
                <p class="user-comment-text"
                   *ngIf="!commentFormShow"
                >
                    {{comment.comment}}
                </p>
                <form class="new-comment-form"
                      *ngIf="commentFormShow"
                      [formGroup]="commentUpdateFormGroup">
                    <div class="form-group">
                        <mat-form-field appearance="fill">
                            <mat-label>Edit Comment</mat-label>
                            <textarea matInput
                                      formControlName="commentUpdate"
                                      (focus)="showBtn()"
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="btn-comment-submit" *ngIf="showFormBtn">
                        <button class="btn-cancel"
                                (click)="closeUpdateCommentForm()">
                            Cancel
                        </button>
                        <button class="btn-done"
                                (click)="updateComment(comment)">
                            Comment
                        </button>
                    </div>
                </form>
                <button mat-button [matMenuTriggerFor]="menu">
                    <span class="material-symbols-outlined">
                more_vert
            </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                            *ngIf="comment.user_id === userData.id"
                            (click)="showUpdateCommentForm(comment)">
                        edit
                        <span class="material-symbols-outlined">
                            flag
                        </span>
                    </button>
                    <button mat-menu-item (click)="openReportDialog()">
                        Report
                        <span class="material-symbols-outlined">
                            flag
                        </span>
                    </button>
                    <button mat-menu-item
                            *ngIf="comment.user_id === userData.id"
                            (click)="openConfirmDialog(comment.id,comment.video_id)">
                        Delete
                        <span class="material-symbols-outlined">
                            flag
                        </span>
                    </button>
                </mat-menu>
            </div>
            <div class="comment-action">
                <p (click)="likeComment(comment)">
                    <span class="material-symbols-outlined like_dislike">
                        thumb_up
                    </span>
                    <span>
                        {{comment.likes_count}}
                    </span>
                </p>
                <p (click)="dislikeComment(comment)">
                    <span class="material-symbols-outlined like_dislike">
                            thumb_down_off
                    </span>
                    <span>
                        {{comment.dislikes_count}}
                    </span>
                </p>
                <p class="reply_comment"
                    (click)="replayCommentFormShowFunc()"
                >Reply</p>
            </div>
            <div *ngIf="comment.reply_count > 0" class="comment-content-view-replies">
                <span (click)="showReplayComment(comment.id)">View  replies</span> <span> {{comment.reply_count}}</span>
            </div>
        </div>
    </div>
    <form class="new-comment-form"
          [formGroup]="commentFormGroup"
          *ngIf="replayCommentFormShow">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>Reply Comment</mat-label>
                <textarea matInput
                          formControlName="comment"
                          (focus)="showBtn()"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
        </div>

        <div class="btn-comment-submit" *ngIf="showFormBtn">
            <button class="btn-cancel" (click)="cancelReplayComment()">Cancel</button>
            <button class="btn-done" (click)="createReplayComment(comment)">Comment</button>
        </div>
    </form>
    <ng-container>
        <div class="comment-list-item-replay" *ngIf="comment.replayComments?.length > 0 && replayComment">
            <ng-container *ngFor="let reCom of comment.replayComments">
                <app-comment-replay
                    [com]="reCom"
                >
                </app-comment-replay>
            </ng-container>
        </div>
    </ng-container>

</div>
