<ng-container *ngIf="(newVideoDataSource$ | async) as state">
    <div class="player-list">
        <div class="current-video-content">
            <div class="video">
                <ng-container *ngIf="videoPath">
                    <video
                        (canplay)="pauseVideo(videoPlayer, videoPath)"
                        (timeupdate)="onTimeUpdate($event)"
                        [src]="videoPath.filename|getImgPath: 'videos'"
                        controls
                        #videoPlayer>
                    </video>
<!--                    <pre>{{videoPath | json}}</pre>-->
                    <div class="videoSave" *ngIf="isAuth">

                        <div class="item-video-desc" (click)="goToProfile()">
                            <div class="item-video-channel">
                                <div class="item-video-channel-img">
                                    <img [src]="videoPath?.user?.avatar|getImgPath:'images'" alt="">
                                </div>
                                <div class="item-video-name_tag">
                                    <div class="item-video-name">
                                        <p class="video-name">{{videoPath?.name}}</p>
                                        <p class="channel-name">{{videoPath?.user?.first_name}} &nbsp;{{videoPath?.user?.last_name}}</p>

                                        <p class="view_date">
                                            <span class="view">{{videoPath?.user?.channel.subscribers_count}} subscribers</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button *ngIf="videoPath.user.id !== authUser?.id" class="subscribeBtn" (click)="subscribeToChannel(videoPath)">Subscribe</button>
                        <!--create-playList start-->
                        <div class="create-playList" *ngIf="state.videos || videoPath">
                            <!--create-playList button start-->
                            <button (click)="openCreatePlayListDialog(state.videos[0],videoPath)">
                                <!--create-playList button icon start-->
                                <span class="material-symbols-outlined">queue_music</span>
                                <!--create-playList button icon end-->

                                <!--create-playList button text start-->
                                <span>Add to playlist</span>
                                <!--create-playList button text end-->
                            </button>
                        </div>
                        <!--create-playList end-->
                    </div>
                    <div class="comment-list">
                        <app-comment-list
                            [videoId]="videoPath.id"
                        ></app-comment-list>
                    </div>
                </ng-container>

                <ng-container *ngIf="!videoPath && state.videos">
                    <video
                        (canplay)="pauseVideo(videoPlayer, state.videos[0])"
                        (timeupdate)="onTimeUpdate($event)"
                        [src]="state.videos[0]?.filename|getImgPath: 'videos'"
                        controls
                        #videoPlayer>
                    </video>

                        <div class="videoSave" *ngIf="isAuth">
                            <!--create-playList start-->
                            <div class="item-video-desc" (click)="goToProfile()">
                                <div class="item-video-channel">
                                    <div class="item-video-channel-img">
                                        <img [src]="state.videos[0]?.user?.avatar|getImgPath:'images'" alt="">
                                    </div>
                                    <div class="item-video-name_tag">
                                        <div class="item-video-name">
                                            <p class="video-name">{{state.videos[0]?.name}}</p>
                                            <p class="channel-name">{{state.videos[0]?.user?.first_name}} &nbsp;{{state.videos[0]?.user?.last_name}}</p>

                                            <p class="view_date">
                                                <span class="view">{{state.videos[0]?.user?.channel.subscribers_count}} subscribers</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button *ngIf="state.videos[0].user.id !== authUser?.id" class="subscribeBtn" (click)="subscribeToChannel(state.videos[0])">Subscribe</button>

                            <div class="create-playList" *ngIf="state.videos || videoPath">
                                <!--create-playList button start-->
                                <button (click)="openCreatePlayListDialog(state.videos[0],videoPath)">
                                    <!--create-playList button icon start-->
                                    <span class="material-symbols-outlined">
                                    queue_music
                                </span>
                                    <!--create-playList button icon end-->

                                    <!--create-playList button text start-->
                                    <span>
                                    Add to playlist
                               </span>
                                    <!--create-playList button text end-->

                                </button>
                            </div>
                            <!--create-playList end-->
                        </div>

                    <div class="comment-list">
                        <app-comment-list
                            [videoId]="state.videos[0]?.id"
                        ></app-comment-list>
                    </div>
                </ng-container>

            </div>


        </div>

        <div class="video-playlist">
            <div class="playlist-desc">
                <div class="playlist-desc-name">
                    <h3>
                        <span class="naem">{{videosType | uppercase}}</span>
                        <!--                        <span class="playlist-video-count">{{videos.length}}</span>-->
                    </h3>
                </div>
                <div class="playlist-privacy">
                    <span>
                        Private
                    </span>
                </div>
            </div>
            <form class="video-list">
                <div class="video-list-item"

                     *ngFor="let video  of state.videos;let i = index">
                    <input
                        type="radio"
                        name="videos"
                        [defaultChecked]="(i === 0)"
                        (change)="getPLayVideoData(video)"
                        [id]="video.id">
                    <label [for]="video.id">
                        <div class="video-state">
                            <div class="video-cover">

                                <!--                            <img src="assets/testVideo.png" alt="">-->
                                <img [src]="video?.thumbnail|getImgPath:'images'" alt="">
                            </div>

                            <div class="video-name">
                                <p class="video-name-text">{{video.name}}</p>
                            </div>
                        </div>
                        <div class="video-view">
                            <span class="material-symbols-outlined">
                                visibility
                            </span>
                            <span>{{video?.views}} views</span>
                        </div>
                    </label>

                </div>
            </form>

        </div>
    </div>
</ng-container>

