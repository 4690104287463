<form class="confirm_dialog">
    <div class="confirm_dialog-title">
        <img src="assets/img/question_mark.svg" alt="">
    </div>

    <div class="confirm-question">
        <p>Are you sure?</p>
    </div>

    <div class="btn-section">
        <button class="remuve-btn" (click)="confirm()">Confirm</button>
        <button class="cancel-btn" (click)="closeDialogConfirm()">Cancel</button>
    </div>
</form>
