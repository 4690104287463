import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {SubjectService} from '@core/services/subject.service';
import { NewVideoStateInitial, NewVidosItemInterface } from '@core/interfaces/new-vidos.interface';
import { NewVideosService } from '@core/services/new-videos.service';
import { MatDialog } from '@angular/material/dialog';
import { AddVideoPlaylistComponent } from '@app/users/new-videos-list-player/add-video-playlist';
import { UserInfoService } from '@core/services/user-info.service';
import { ChannelsService } from '@core/services/channels.service';
import { DeviceUUID } from 'device-uuid';
import { VideoService } from '@core/services/video.service';

@Component({
    selector: 'app-new-videos-list-player',
    templateUrl: 'new-videos-list-player.component.html',
    styleUrls: ['new-videos-list-player.component.scss']
})

export class NewVideosListPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('videoPlayer') videoplayer: any;
    public isAuth = false;
    public newVideoDataSource$: Observable<NewVideoStateInitial> | undefined;
    public videoPath: any;
    public currentPlayVideo: any;
    public videosType: string;
    public show = false;
    public paymentModeStatus;
    private sort = {
        key: 'created_at',
        value: ''
    };
    subscriptions: Subscription[] = [];
    subscribedToChannel = false;
    subscribersCount = 0;
    @Input() authUser;

    constructor(
        private _router: ActivatedRoute,
        private _newVideosService: NewVideosService,
        private channelService: ChannelsService,
        private _userInfoService: UserInfoService,
        private subject: SubjectService,
        private dialog: MatDialog,
        private videoService: VideoService,
    ) {

        this.isAuth = Boolean(localStorage.getItem('token'));
        this._getAuthInfo();
        this._getType();
        if (this.videosType === 'new') {
            this.newVideoDataSource$ = this._newVideosService.newVideosState$.state$;
        } else {
            this.newVideoDataSource$ = this._newVideosService.tradingVideosState$.state$;
        }
        this.checkChannelSubscription();

    }

    ngOnInit() {
        this.setVideosState();
        setTimeout(() => {
            this.checkChannelSubscription();
        }, 500);
        const uuid = new DeviceUUID().get();
        // console.log(uuid, 'uuid');

    }

    private _getAuthInfo() {
        this._userInfoService._userInfo.subscribe((data) => {
            this.authUser = data;
        });
    }


    private _getType() {
        this._router.params.subscribe((params: Params) => {
            this.videosType = params.id;
        });
    }


    private setVideosState() {
        switch (this.videosType) {
            case 'new':
                this.sort.value = 'created_at';
                this._newVideosService.getNewVideosApi(this.sort);
                break;
            case 'views':
                this.sort.value = 'views';
                this._newVideosService.getTradingVideosApi(this.sort);
                break;
        }
    }

    public getPLayVideoData(e: any) {
        this.videoPath = e;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    public onTimeUpdate(event: Event) {
        const videoElement = this.videoplayer.nativeElement as HTMLVideoElement;
        const currentTime = videoElement.currentTime;
        const params = {user_id: this.authUser?.id, video_id: this.currentPlayVideo.id};

        // tslint:disable-next-line:triple-equals
        if (currentTime >= 30 && currentTime < 30.4) {
            // this.updateViewsCount(dt);
            console.log(this.currentPlayVideo, 'Video has played for 30 seconds.');
            this.subscriptions.push(this.videoService.updateViews(params).subscribe((d) => {
                this.currentPlayVideo = d;
            }));

        }
    }


    public pauseVideo(videoplayer, video) {
        this.currentPlayVideo = video;
        setTimeout(() => {
            videoplayer.play();
        }, 1000);
        // this.startedPlay = true;
        // if(this.startedPlay == true)
        // {
        // setTimeout(() => {
        //     videoplayer.nativeElement.pause();
        //     if (videoplayer.nativeElement.paused) {
        //         this.show = !this.show;
        //     }
        // }, 5000);
        // }
    }

    public openCreatePlayListDialog(first: NewVidosItemInterface, current: any) {
        console.log(first, current);
        const dialogRef = this.dialog.open(AddVideoPlaylistComponent, {
            height: 'auto',
            width: '350px',
            panelClass: 'custom-dialog-container',
            data: {
                id: current ? current.id : first.id
            }
        });
    }
    public goToProfile() {
        console.log('goToProfile');
    }
    // public subscribeChannel(item: any) {
        // const itemData = {
        //     user_id: this.user_info._value.id,
        //     channel_id: item.channel_id
        // };
        // this._newVideosService.channelSubscribe(itemData);
    // }
    subscribeToChannel(user): void {
        // this.connectWithUser(user);
        this.subscriptions.push(this.channelService.subscribeToChannel({
            user_id: this.authUser.id,
            channel_id: user.channel.id
        }).subscribe(dt => {
            this.subscribedToChannel = dt.status === 'Subscribed';
            this.subscribersCount = dt.subscribers_count;
            this.subscriptions.push(this.channelService.getUserChannelSubscriptions({user_id: this.authUser.id}).subscribe(d => {
                this.subject.setUserSubscriptions(d);
            }));
            // this.socketService.subscribeChanel({
            //     from_user: user,
            //     to_user: this.authUser,
            //     msg: `You have successfully subscribed to Gold Subscription for ${user.username}'s channel!`
            // });
        }));
    }
    checkChannelSubscription() {
        // if (this.videoPath) {
        //     console.log(this.videoPath);
        // } else {
        //     console.log(this.newVideoDataSource$);
        // }
        // todo checkChannelSubscription

        // this.subscriptions.push(this.channelService.checkChannelSubscription({
        //     user_id: this.authUser.id,
        //     channel_id: 1
        // }).subscribe(dt => {
        //     this.subscribedToChannel = dt.status === 'Subscribed';
        //     this.subscribersCount = dt.subscribers_count;
        // }));
    }


    ngOnDestroy() {
    }
}
