<div class="invite-channel-members-wrapper">
    <mat-icon class="mat-icon-close">
        close
    </mat-icon>
    <div class="invite-channel-members-section-filter">
        <input type="text" placeholder="Search">
        <mat-icon>
            search
        </mat-icon>
    </div>
    <div class="top-members-section">
        <div class="top-members-section-title">
            <h4>Top communities / channel or your Friends</h4>
        </div>
        <div class="members-block">
<!--            <div class="member" *ngFor="let user of usersConnection">-->
<!--                <div class="images-members">-->
<!--                    <img [src]="user.avatar  | getImgPath:'images'" alt="">-->
<!--                    <span class="member-name">{{user.name}} </span>-->
<!--                </div>-->
<!--                <button class="invite-btn" (click)="inviteUserInGroup(user)">-->
<!--                    <mat-icon>-->
<!--                        {{'add'}}-->
<!--                    </mat-icon>-->
<!--                    <span>-->
<!--                        Invite-->
<!--                    </span>-->
<!--                </button>-->
<!--            </div>-->
        </div>
    </div>
    <!--    <div class="other-channel-section">-->
    <!--        <div class="other-members-section-title">-->
    <!--            <h4>Other-channel</h4>-->
    <!--        </div>-->
    <!--        <div class="members-block">-->
    <!--            <div class="member">-->
    <!--                <div class="images-members">-->
    <!--                    <img src="assets/img/chatUser1.png" alt="">-->
    <!--                    <span class="member-name">Name Surname</span>-->
    <!--                </div>-->
    <!--                <button class="invite-btn">-->
    <!--                    <mat-icon>-->
    <!--                        {{'add'}}-->
    <!--                    </mat-icon>-->
    <!--                    <span>-->
    <!--                        Invite-->
    <!--                    </span>-->
    <!--                </button>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="invite-btn">
        <button>Ok</button>
    </div>
</div>
