<div class="d-flex justify-content-center">
    <div class="rightside">
        <div class="rightside-header">
            <img src="../../../../../assets/img/metltvlogo.svg" alt="" class="mt-4" width="146">
            <div class="d-flex justify-content-center mt-3">
                <p class="logo-text ">Metl.tv Pro</p>
                <div class="logo-text-img ml-3">
                    <img src="../../../../../assets/img/shuttle%20white.svg" alt="">
                </div>
            </div>
        </div>
        <div class="rightside-main p-5">
            <h6 class="main-title">What's Included</h6>
            <p class="rightside-main-text mb-5">With Metl.tv Pro gets even better with features you can't get anywhere else.</p>
            <div class="d-flex flex-column align-items-center mx-auto mb-4">
                <div class="melt-card d-flex justify-content-between  p-3">
                    <div class="melt-card-img  d-block m-auto">
                        <img src="../../../../../assets/img/stop.svg" alt="">
                    </div>
                    <div class="card-text mt-2 ml-3">
                        <h5>Ad Free Viewing (with limited exceptions)</h5>
                        <p>Watching with Metl.tv Pro means no pre-rolls,
                            no mid-rolls, no companions, and no display ads.
                            You may still be presented promotions and ads that are embedded into a broadcast or,
                            in rare situations, delivered with certain simulcast content.
                        </p>
                    </div>

                </div>
                <div class="melt-card d-flex justify-content-between  p-3">
                    <div class="melt-card-img  d-block m-auto">
                        <img src="../../../../../assets/img/wink.svg" alt="">
                    </div>
                    <div class="card-text mt-2 ml-3">
                        <h5>Chat Badge</h5>
                        <p>
                            Represent Metl.tv proudly with an exclusive chat badge.
                        </p>
                    </div>

                </div>
                <div class="melt-card d-flex justify-content-between  p-3">
                    <div class="melt-card-img d-block m-auto">
                        <img src="../../../../../assets/img/shuttle.svg" alt="">
                    </div>
                    <div class="card-text mt-2 ml-3">
                        <h5>Expanded Emote Set</h5>
                        <p>
                            Choose from 2 additional sets of emotes. Glitch or monkeys; choose wisely (you can always change it later).
                        </p>
                    </div>

                </div>
                <div class="melt-card d-flex justify-content-between  p-3">
                    <div class="melt-card-img  d-block m-auto">
                        <img src="../../../../../assets/img/brush.svg" alt="">
                    </div>
                    <div class="card-text mt-2 ml-3">
                        <h5>Custom Chat Username Colors</h5>
                        <p>Stand out in chat with a custom username color.
                        </p>
                    </div>

                </div>
            </div>
            <button type="submit" class="subscribe-btn d-block m-auto mt-5">Subscribe</button>
            <p class="price-text mt-2">$14.99 / Month</p>

        </div>

    </div>
</div>
