<div class="cookie-policy text-list">
    <h1>METL.TV COOKIE POLICY</h1>
    <div class="text">
        <p class="bold">INTRODUCTION</p>
        <p>[Market Exchange Trading Live LLC] (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website [METL.TV], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize METL.TV and improve your experience.</p>
        <p>We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on METL.TV, and you waive the right to receive specific notice of each such change or modification.</p>
        <p>You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of METL.TV after the date such revised Cookie Policy is posted.</p>
        <p class="bold">USE OF COOKIES</p>
        <p>A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to METL.TV. We use cookies on METL.TV to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into METL.TV, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how METL.TV is being used and improve your user experience.</p>
        <p class="bold">TYPES OF COOKIES</p>
        <p>The following types of cookies may be used when you visit METL.TV:</p>
        <p class="bold green">Advertising Cookies</p>
        <p>Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad servers to gather information about your visits to METL.TV and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. These cookies are linked to a computer and do not gather any personal information about you.</p>
        <p class="bold green">Analytics Cookies</p>
        <p>Analytics cookies monitor how users reached METL.TV, and how they interact with and move around once on METL.TV. These cookies let us know what features on METL.TV are working the best and what features on METL.TV can be improved.</p>
        <p class="bold green">Our Cookies</p>
        <p>Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which METL.TV won't work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of METL.TV.</p>
        <p class="bold green">Personalization Cookies</p>
        <p>Personalization cookies are used to recognize repeat visitors to METL.TV. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit METL.TV.</p>
        <p class="bold green">Security Cookies</p>
        <p>Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.</p>
        <p class="bold green">Site Management Cookies</p>
        <p>Site management cookies are used to maintain your identity or session on METL.TV so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.</p>
        <p class="bold green">Third-Party Cookies</p>
        <p>Third-party cookies may be place on your computer when you visit METL.TV by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser.</p>
        <p class="bold">CONTROL OF COOKIES</p>
        <p>Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of METL.TV.</p>
        <p>For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:</p>
        <a href="" class="red">Apple Safari </a>
        <a href="" class="red">Google Chrome</a>
        <a href="" class="red">Microsoft Edge</a>
        <a href="" class="red">Microsoft Internet Explorer</a>
        <a href="" class="red">Mozilla Firefox</a>
        <a href="" class="red">Opera</a>
        <a href="" class="red">Android (Chrome)</a>
        <a href="" class="red">Blackberry</a>
        <a href="" class="red">Iphone or Ipad (Chrome)</a>
        <a href="" class="red">Iphone or Ipad (Safari)</a>
        <p>In addition, you may opt-out of some third-party cookies through the  <a href="" class="red"> Network Advertising Initiative’s Opt-Out Tool.</a></p>
        <p class="bold">OTHER TRACKING TECHNOLOGIES</p>
        <p>In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on METL.TV to help customize METL.TV and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.</p>
        <p class="bold">PRIVACY POLICY</p>
        <p>For more information about how we use information collected by cookies and other tracking technologies, please refer to our Privacy Policy posted on METL.TV. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using METL.TV, you agree to be bound by this Cookie Policy and our Privacy Policy.</p>
        <p class="bold">CONTACT US</p>
        <p>If you have questions or comments about this Cookie Policy, please contact us at:</p>
        <a href=""><i class="fas fa-envelope"></i> info@metl.tv</a>
    </div>
</div>