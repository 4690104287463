<form [formGroup]="paymentsFilterForm">
    <div class="d-flex align-items-center flex-wrap wholeDiv" formGroupName="created">
        <div class="mr-3 form-group m-0">
            <mat-form-field class="firstOne date-div" >
                    <mat-label class="labelDate">Start</mat-label>
                    <input   matInput [matDatepicker]="picker1" formControlName="gte"
                       (dateChange)="dateChanged($event,'gte')">
                <mat-datepicker-toggle  class="datepickerIcon" matSuffix [for]="picker1" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="mr-3 form-group m-0">
            <mat-form-field class="date-div">
                <mat-label  class="labelDate">End</mat-label>

                <input  matInput [matDatepicker]="picker2" formControlName="lte"
                       (dateChange)="dateChanged($event,'lte')">
                <mat-datepicker-toggle   class="datepickerIcon"  matSuffix [for]="picker2" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</form>
