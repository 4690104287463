<ul class="categories">
    <li><a class="active">Recommendations</a></li>
    <li><a>Today's picks</a></li>
    <li><a>AAPL</a></li>
    <li><a>Phil Ramirez</a></li>
    <li><a>True Money</a></li>
    <li><a>PICK 4</a></li>
    <li><a>Synergy fusion</a></li>
    <li><a>Sunshine trading stream</a></li>
    <li><a>Shot stocks</a></li>
    <li><a>SMP</a></li>
    <li><a>Strategy for U</a></li>
    <li><a>Automotive</a></li>
</ul>
