<div class="uploadModal">
    <div class="header">
        <h1>{{title}}</h1>
        <mat-icon class="iconClosedModal" (click)="closedModal()">close</mat-icon>
    </div>
    <div *ngIf="(countUploadFile === 'oneFile')" class="inputFile">
        <button class="buttonUpload" (click)="uploadFile.click()">
            <mat-icon>save_alt</mat-icon>
            <span>Upload</span>
        </button>
        <input type="file" hidden #uploadFile (change)="videoUpload($event)">
    </div>
    <div *ngIf="!(countUploadFile === 'oneFile')" class="inputFile">
        <button class="buttonUpload" (click)="uploadFile.click()">
            <mat-icon>save_alt</mat-icon>
            <span>Upload</span>
        </button>
        <input type="file" hidden multiple #uploadFile (change)="videoUpload($event)">
    </div>
    <div *ngFor="let file of files; let i = index">
        <div *ngIf="showUploadImg" class="progressFile">
            <div class="imgAndName">
                <div class="img">
                    <mat-icon *ngIf="file.type.includes('video')">movie</mat-icon>
                    <img *ngIf="file.type.includes('image')" [src]="file.src" alt="">
                </div>
                <div class="name">{{file.name}}</div>
            </div>
            <div class="progressBar" *ngIf="showProgressBar">
                {{percentProgressBar}}%<mat-progress-bar class="matProgressBar" mode="determinate" [value]="percentProgressBar"></mat-progress-bar>
            </div>
            <div class="cancel" *ngIf="showProgressBar">
                <a>Cancel</a>
            </div>
        </div>
    </div>
    <div class="uploadedFile">
        <div class="headUploaded">Uploaded Files</div>
        <div *ngFor="let file of files; let i=index">
            <div *ngIf="percentProgressBar === 100 || showUploadImg" class="listUploaded">
                <div class="uploadedFileIconName">
                    <div class="icon">
                        <mat-icon *ngIf="file.type.includes('video')">movie</mat-icon>
                        <img *ngIf="file.type.includes('image')" [src]="file.src" alt="">
                    </div>
                    <div class="uploadedFileName">{{file.name}}</div>
                </div>
                <div class="closedUploadedFile"  (click)="canselFile(file, i)">
                    <mat-icon>close</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="button">
        <button class="canselButton" (click)="closedModal()">Cancel</button>
        <button class="saveButton" (click)="saveFile()">Save</button>
    </div>
</div>
