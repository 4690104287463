<div class="post-item" *ngIf="post">
    <div class="posted">
        <p>
            Posted by <span [routerLink]="'/users/'+post.post_author.username"
                            class="author-username">{{post.post_author.username}}</span>
            <ng-container *ngIf="!accessedFromGroup && post.post_group as group">
                <i class="fas fa-arrow-right group-pointer"></i>
                <span class="post-group-name" [routerLink]="'/groups/'+group.custom_name+'/about'">{{group.name}}</span>
            </ng-container>
        </p>
        <p>{{ post.views + ' views ' }}{{ post.created_at| dateFromNow }}</p>
    </div>

    <div class="info">
        <div class="left-wrap">
            <div class="voting-container">
                <button class="vote-up-btn" [class.active]="isPostVotedByAuthUser(1)" (click)="voteForPost(1, post)" [disabled]="">
                    <span class="material-icons">forward</span>
                </button>
                <div class="vote-count">{{post.votes}}</div>
                <button class="vote-down-btn" [class.active]="isPostVotedByAuthUser(-1)" (click)="voteForPost(-1, post)">
                    <span class="material-icons">forward</span>
                </button>
            </div>
        </div>
        <div class="right-wrap">
            <div [innerHTML]="post.description" [routerLink]="'/posts/'+post.id"></div>

            <img *ngIf="post?.cover_img?.includes('image')" class="post-cover" [routerLink]="'/posts/'+post.id" [src]="post.cover_img|getImgPath: 'images'">
            <video *ngIf="post?.cover_img?.includes('video')" [routerLink]="'/posts/'+post.id" [src]="post.cover_img|getImgPath: 'videos'" controls ></video>
<!--            <video src="https://metl.tv/uploads/videos/video_1652789198844.mp4" controls ></video>-->

            <div class="post-menu">
                <div class="menu">
                        <a *ngIf="commentsField"><span class="material-icons">chat_bubble</span>Comments</a>
                        <a (click)="openSocialShareModal()"><span class="material-icons">share</span>Share</a>
                </div>
                <div class="dotes" *ngIf="authUser.id === post.author_id">

                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <i class="material-icons">more_vert</i>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editPost(post)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="deletePost(post)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>
