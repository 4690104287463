<div class="chat-message-form" [class.embed]="embed">
    <form [formGroup]="chatForm" class="chat-form">
                    <textarea cdkTextareaAutosize rows="4" *ngIf="!embed" class="flex-1 mx-3" formControlName="message"
                              placeholder="Type your message" id="message-textarea"
                              (keyup)="setTyping()" (focus)="setSeen()"
                              (keyup.enter)="sendMessageOnEnter($event)">
                    </textarea>
        <div class="embed-textarea" *ngIf="embed">


<!--            <button class="send-btn" (click)="sendMessage()"><img alt="send button" src="assets/img/send.svg"></button>-->


            <div class="greyOpenedDiv">
                <div class="insideWholeDiv">
                    <div class="insideBigDiv">
                        <div class="insideDiv" *ngFor="let file of files; let i=index">
                            <div class="iconInsideDiv">
                                <img *ngIf="file.file.type.includes('video')" class="chatUser1Img" src="../../assets/img/chatIcon1.png" alt="chatIcon video">
                                <img *ngIf="file.file.type.includes('image')" class="chatUser1Img" src="../../assets/img/chatIcon2.png" alt="chatIcon image">
                                <img *ngIf="!file.file.type.includes('video') && !file.file.type.includes('image')" class="chatUser1Img" src="../../assets/img/chatIcon3.png" alt="chatIcon file">
                            </div>
                            <div class="fileInsideDiv">{{file.file.name}}</div>
                            <div class="closeDarkIconDiv" (click)="removeMessageFile(file.file)">
                                <img *ngIf="!file.progress" class="closingDarkImg" src="../../assets/img/closeDarkIcon.png" alt="closeIcon">
                                <mat-progress-spinner *ngIf="file.progress"
                                    class="example-margin"
                                    [color]="'primary'"
                                    [mode]="mode"
                                    [value]="file.progressValue">
                                </mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="embed-div">
                    <button class="add-btn" (click)="input.click()">+</button>
                    <input type="file" hidden multiple #input (change)="chatFile($event)" accept="image/*, video/*, text/*">
                    <textarea class="flex-1 mx-3" cdkTextareaAutosize formControlName="message" placeholder="Message"
                              (keyup)="setTyping()" (focus)="setSeen()" (keyup.enter)="sendMessageOnEnter($event)" #textAreaMsg>
                    </textarea>
                </div>
            </div>
            <div class="sendingGreenBtnOpened" (click)="sendMessage()">
                <img class="sendingBtn" src="../../assets/img/chatIcon5.png">
            </div>


        </div>
    </form>
    <div class="icons" [hidden]="isProduction || embed">
        <a href=""><img src="assets/img/gif-24px.svg" alt=""></a>
        <a href=""><img src="assets/img/mood-24px.svg" alt=""></a>
        <a (click)="sendMessage()"><img src="assets/img/send-24px.svg" alt=""></a>
    </div>
</div>
