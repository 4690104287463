<div class="imageCropper">
    <div class="header">
        <h1>{{title}}</h1>
        <mat-icon class="iconClosedModal" (click)="closedModal()">close</mat-icon>
    </div>

    <div class="img-container" [ngClass]="{'circle': shape === 'circle'}">
        <img #image [src]="imageSrc" (load)="loadImage()" crossorigin alt="">
    </div>

    <div class="img-save-button">
        <button (click)="saveImg()">Save Image</button>
    </div>

</div>
