
<section class="about-wrap">
    <div class="inner-wrap">
        <div class="text-img">

            <div class="text">
                <h1>About Us</h1>
                <p>METL.TV was created with a certain kind of user in mind. With the onset of zero-commission trading, we saw an opportunity to build a platform that provided entertainment to anyone who wanted to invest. Whether you are new to the market or a seasoned trader we are sure you will be able to interact with our site in a very user-friendly experience. We are constantly striving to provide a comprehensive experience that anyone can use. The world of trading can be lonely at times and we strive to bring together traders from around the world to our METL.TV community. Thank you so much for checking us out and we are excited to provide you with a great entertainment experience!</p>
                <p>Please keep in mind METL.TV if for <span>entertainment purposes only.</span> None of the personalities (Streamers and Viewers) shown on this website are investment advisors or brokers and are not registered as such. All opinions expressed by the personalities in any broadcast on METL.TV are solely the personalities’ opinions and neither reflect the opinions nor are made on the behalf of METL LLC or any of its respective affiliates.</p>

            </div>
            <div class="img"><img src="../../../../../assets/img/img2.png" alt=""></div>
        </div>

        <div class="text">
            <p>The trading and investing ideas and strategies discussed on METL.TV are not recommendations of any kind, but are made available solely for entertainment purposes. Investments or strategies mentioned in any content on METL.TV may not be suitable for your particular investment objectives, financial situation or needs. Any personalities’ opinions are based upon information they consider reliable, but no representation or warranty is made by any person as to its completeness or accuracy.</p>
            <p>Any of the personalities on METL.TV may hold positions in the securities discussed in a stream, chat room, or direct message, including positions not discussed on METL.TV, and in other securities./p>
            <p>METL.TV content will never be an offer to sell or a solicitation to of an offer to buy any securities. Before acting on information on METL.TV, you should consider whether is it suitable for your particular circumstances and strongly consider seeking advice from your own financial or investment adviser. Any investment in any security Is subject to a number of risks, and that discussions of any security on this broadcast will not identify or describe all or, in some cases, any relevant risk factors.</p>
            <p>Some of the stocks, ETF’s, and cryptocurrencies talked about on METL.TV have a low market capitalization, and/or limited public float. Those stocks are subject to more risk than stocks of larger companies, including greater volatility, lower liquidity and less publicly available information. You should be aware of the real risk of loss in following any strategy or investment discussed on METL.TV, including the acquisition of any security.</p>
            <p>We understand that the markets can offer the same type of thrill as gambling. If you have a gambling addiction please seek help.</p>
            <p class="green">To reach out to the National Problem Gaming Helpline dial 1-800-522-4700.</p>
        </div>
    </div>
</section>
