export const environment = {
    apiUrl: 'https://metl.tv/',
    socketUrl: 'https://metl.tv/',
    // apiUrl: 'http://192.168.77.125:3001/',
    // socketUrl: 'http://192.168.77.125:3001/',
    // apiUrl: 'http://192.168.77.139:3001/',
    // socketUrl: 'http://192.168.77.139:3001/',
    envName: 'development',
    production: false
};
