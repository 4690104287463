import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportCommentDialog } from '@shared/components/commnet-list';
import { CommentInterface, VideoCommentRepliesInterface } from '@core/interfaces/comment.interface';
import { UserInfoService } from '@core/services/user-info.service';
import { NewPlaylistService } from '@core/services/new-playlist.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrentUserData } from '@core/interfaces';
import { DeleteCommentDialog } from '@shared/components/commnet-list/delete-comment';
import { SocketIoService } from '@core/services/socket-io.service';
import { Observable } from 'rxjs';
import { ReplayCommentInitialState } from '@core/interfaces/replay-comment.interface';
import {ChatService} from '@core/services/chat.service';

@Component({
    selector: 'app-comment-list-item',
    templateUrl: 'comment-list-item.component.html',
    styleUrls: ['comment-list-item.component.scss']
})

export class CommentListItemComponent implements OnInit, OnDestroy {
    @Input() comment: CommentInterface | undefined;
    public replayComments$: Observable<ReplayCommentInitialState> | undefined;
    public commentUpdateFormGroup: FormGroup | undefined;
    public replayComment = false;
    public commentFormGroup: FormGroup | undefined;
    public userData: CurrentUserData;
    public showFormBtn = false;
    public commentFormShow = false;
    public replayCommentFormShow: true | false = false;

    constructor(
        private _fb: FormBuilder,
        private _socketIoService: SocketIoService,
        private _userInfoService: UserInfoService,
        private _dialog: MatDialog,
        private _newPlaylistService: NewPlaylistService,
        private dialog: MatDialog,
        private chatService: ChatService,
    ) {
        this.replayComments$ = this._newPlaylistService.replayCommentState$.state$;
        this._currentUserData();
    }

    ngOnInit() {
        this._FormBuilder();
        this._socketIoService.createComment_success().subscribe((data) => {
            this._newPlaylistService.videoCommentState$.setLoading(true);
            let dataParse: string | undefined;
            if (typeof data === 'string') {
                dataParse = JSON.parse(data);
            }
        });
    }

    public showBtn() {
        this.showFormBtn = true;
    }

    public showUpdateCommentForm(com) {

        this.commentUpdateFormGroup.patchValue({
            commentUpdate: com.comment
        });
        this.commentFormShow = true;
    }

    public closeUpdateCommentForm() {
        this.commentFormShow = false;
    }

    public replayCommentFormShowFunc() {
        this.replayCommentFormShow = !this.replayCommentFormShow;
    }

    public openReportDialog() {
        const dialogRef = this.dialog.open(ReportCommentDialog, {
            height: '420px',
            width: '400px',
            panelClass: 'custom-dialog-container'
        });
    }

    private _currentUserData() {
        this._userInfoService._userInfo
            .subscribe((data: CurrentUserData) => {
                this.userData = data;
            });
    }

    private _FormBuilder() {
        this.commentFormGroup = this._fb.group({
            comment: [null, Validators.required]
        });

        this.commentUpdateFormGroup = this._fb.group({
            commentUpdate: [null, Validators.required]
        });
    }

    public showReplayComment(id) {
        this.replayComment = !this.replayComment;
        this._newPlaylistService.getVideoCommentReplay(id);

    }


    public createReplayComment(commentInfo) {
        if (this.commentFormGroup.invalid) {
            return;
        }

        const OBJ_SET_COMMENT = {
            comment: this.commentFormGroup.get('comment').value,
            from_id: this.userData.id,
            post_id: null,
            is_reply: true,
            video_id: commentInfo.video_id,
            parent_comment: commentInfo.id,
            files: []
        };

        this._socketIoService.addComment(OBJ_SET_COMMENT);
        this._FormBuilder();
        this.replayCommentFormShowFunc();
    }
    public cancelReplayComment() {
        this.replayCommentFormShow = !this.replayCommentFormShow;
    }

    public updateComment(commentInfo) {
        if (this.commentUpdateFormGroup.invalid) {
            return;
        }

        const OBJ_SET_COMMENT = {
            comment: this.commentUpdateFormGroup.get('commentUpdate').value,
            from_id: this.userData.id,
            id: commentInfo.id,
            is_reply: 0,
            to_comment_id: 0,
            to_reply_id: 0,
            to_user_id: 0,
            video_id: commentInfo.video_id
        };

        this._newPlaylistService.updateComment(OBJ_SET_COMMENT);
    }

    public openConfirmDialog(comment_id: number, video_id: number) {
        this._dialog.open(DeleteCommentDialog, {
            height: 'auto',
            width: 'auto',
            data: {
                comment_id,
                video_id,
                user_id: this.userData.id
            }
        });
    }
    public likeComment(commentInfo) {
        console.log(commentInfo, 'like comment');
        const OBJ_SET_LIKE = {
            // from_id: this.userData.id,
            // post_id: null,
            // is_reply: false,
            // video_id: this.videoId,
            // parent_comment: null,
            // files: []
            comment_id: commentInfo.id
        };
        this._socketIoService.likeComment(OBJ_SET_LIKE);
        const OBJ = {
            type: 'video',
            video_id: commentInfo.video_id
        };
        this._newPlaylistService.getVideoComment(OBJ);
        this._socketIoService.listenToNotifications().subscribe((data) => {
            // tslint:disable-next-line:prefer-const
            let dataParseN: string | undefined;
            if (typeof data === 'string') {
                dataParseN = JSON.parse(data);
            }
        });
    }
    public dislikeComment(commentInfo) {
        console.log(commentInfo, 'dislike comment');
        const OBJ_SET_DISLIKE = {
            // from_id: this.userData.id,
            // post_id: null,
            // is_reply: false,
            // video_id: this.videoId,
            // parent_comment: null,
            // files: []
            comment_id: commentInfo.id
        };
        this._socketIoService.dislikeComment(OBJ_SET_DISLIKE);
        const OBJ = {
            type: 'video',
            video_id: commentInfo.video_id
        };
        this._newPlaylistService.getVideoComment(OBJ);
        this._socketIoService.listenToNotifications().subscribe((data) => {
            // tslint:disable-next-line:prefer-const
            let dataParseN: string | undefined;
            if (typeof data === 'string') {
                dataParseN = JSON.parse(data);
                console.log(dataParseN, 'listenToNotifications');
            }
        });
    }
    toMessenger(commentInfo) {
        if (this.userData.id !== commentInfo.user.id) {
            this.chatService.createConversation({targetUser: commentInfo.user.id});
        }

    }

    ngOnDestroy() {
    }
}
