<section class="help-wrap">
    <div class="inner-wrap">
        <div class="img"><img src="../../../../../assets/img/img1.png" alt=""></div>
        <div class="text">
            <h1>Help</h1>
            <p>At METL.TV we take all of your concerns and suggestions seriously. We look to offer each of our customers with a superior interactive experience. With this being our initial launch, we are constantly working to make this a great site that anyone can enjoy. Because of our initial limitations, we ask that you email us any concerns or suggestions at <a href="">info@metl.tv</a> and we will be happy to respond within 48 hours.</p>

            <p class="green">Thank you for all of your input and patience with our initial growth.</p>
        </div>
    </div>
</section>
