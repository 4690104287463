import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purchase-coins',
  templateUrl: './purchase-coins.component.html',
  styleUrls: ['./purchase-coins.component.scss']
})
export class PurchaseCoinsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
