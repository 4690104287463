<section class="contact-wrap">
    <div class="form">
        <div class="inner-wrap">
            <h2>Contact us</h2>
            <form action="">
                <div class="input-wrap">
                    <div class="input">
                        <label for="">Full Name:</label>
                        <input type="text" placeholder="First name, last name">
                    </div>
                    <div class="input">
                        <label for="">Email Address:</label>
                        <input type="text" placeholder="Email">
                    </div>
                    <div class="input">
                        <label for="">Text</label>
                        <textarea name="" id="" cols="30" rows="10" placeholder="Your text here"></textarea>
                    </div>
                </div>
                <button class="btn">Send</button>
            </form>
        </div>
    </div>

    <div class="map-info">
        <div class="inner-wrap">
            <div class="map">
                <iframe src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>

            <div class="info">
                <ul>
                    <li>
                        <h2>Address</h2>
                        <p><span class="material-icons">place</span>Lorem ipsum dolor sit amet</p>
                    </li>
                    <li>
                        <h2>Call Us</h2>
                        <p><span class="material-icons">phone</span>+1 202 555 0156</p>
                    </li>
                    <li>
                        <h2>Open Hours</h2>
                        <p><span class="material-icons">watch_later</span>09:00 - 18:00 </p>
                    </li>
                    <li>
                        <h2>Follow us</h2>
                        <p>
                            <a href=""><img src="assets/img/facebook.svg" alt=""></a>
                            <a href=""><img src="assets/img/twitter.svg" alt=""></a>
                            <a href=""><img src="assets/img/instagram.svg" alt=""></a>
                            <a href=""><img src="assets/img/linkedin.svg" alt=""></a>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
