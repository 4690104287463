import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-turbo-plan',
  templateUrl: './turbo-plan.component.html',
  styleUrls: ['./turbo-plan.component.scss']
})
export class TurboPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
