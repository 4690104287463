import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { buildPlayVideoRoute } from '@core/helpers/build-play-video-route';
import { Router } from '@angular/router';

@Component({
    selector: 'app-new-video-list-item',
    templateUrl: 'new-video-list-item.component.html',
    styleUrls: ['new-video-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class AppNewVideoListItemComponent implements OnInit, OnDestroy, OnChanges {
    @Input() data;
    @Input() loading = true;
    @Input() type;
    @Input() id;

    constructor(private router: Router) {
    }
    ngOnChanges(changes: SimpleChanges) {
        // console.log('SimpleChangesqqqqqqqqqqqq ', changes);
    }

    ngOnInit() {
        // console.log(this.id, 'ddddddddddddddd');
    }

    public async openVideoPage(video, username) {
        const r = buildPlayVideoRoute(video, username);
        console.log('buildPlayVideoRoute', r);
        await this.router.navigate([r.route], { queryParams: r.params });
    }

    ngOnDestroy() {
    }
}
