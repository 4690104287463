import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-new-video-list-skeleton',
    templateUrl: 'new-video-list-skeleton.component.html',
    styleUrls: ['new-video-list-skeleton.component.scss']
})


export class AppNewVideoListSkeletonComponent implements OnInit, OnDestroy {

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
